import LayoutCoachIndex from '@/layout/CoachIndex.vue'
import UserCenter from '@/layout/UserCenter.vue';

export const asyncCoachRouterMap = [
  {
    path: '/',
    redirect: '/coaches',
    component: LayoutCoachIndex,
    children: [
      {
        path: 'coaches',
        name: '教练首页',
        component: () => import('../views/coaches/Index.vue'),
        meta: {
          title: '教练首页'
        }
      }
    ]
  },
  {
    path: '/course',
    component: UserCenter,
    children: [
      {
        path: 'index',
        name: '我的团课',
        component: () => import('../views/coaches/Courses.vue'),
        meta: {
          title: '我的团课'
        }
      },
      {
        path: 'opening',
        name: '待开课程',
        component: () => import('../views/coaches/CourseOpening.vue'),
        meta: {
          title: '我的团课-待开课程'
        }
      },
      {
        path: 'open',
        name: '我的团课-待开',
        component: () => import('../views/coaches/CourseOpen.vue'),
        meta: {
          title: '我的团课-待开'
        }
      },
    ]
  },
  {
    path: '/course',
    component: UserCenter,
    children: [
      {
        path: 'pt',
        name: '私教中心',
        component: () => import('../views/coaches/personal-training/Index.vue'),
        meta: {
          title: '私教中心'
        }
      },
      {
        path: 'pt-apply',
        name: '申请私教',
        component: () => import('../views/coaches/personal-training/PtApply.vue'),
        meta: {
          title: '私教中心--申请私教'
        }
      },
      // {
      //   path: 'pt-list',
      //   name: '私教安排',
      //   component: () => import('../views/coaches/personal-training/PtList.vue'),
      //   meta: {
      //     title: '私教中心--私教安排'
      //   }
      // },
      // {
      //   path: 'pt-add',
      //   name: '发布时间段',
      //   component: () => import('../views/coaches/personal-training/PtAdd.vue'),
      //   meta: {
      //     title: '私教中心--发布时间段'
      //   }
      // },
      {
        path: 'my-pt',
        name: '我的私教',
        component: () => import('../views/coaches/personal-training/MyPt.vue'),
        meta: {
          title: '私教中心--我的私教'
        }
      },
      {
        path: 'pt-order',
        name: '已购私教',
        component: () => import('../views/coaches/personal-training/PtOrder.vue'),
        meta: {
          title: '私教中心--已购私教'
        }
      }
    ]
  },
  {
    path: '/course',
    name: '课程详情',
    component: LayoutCoachIndex,
    children: [
      {
        path: 'info',
        name: 'info',
        component: () => import('../views/coaches/personal-training/MyCourseInfo.vue'),
        meta: {
          title: '我的私教-课程详情'
        }
      }
    ]
  },
  {
    path: '/user',
    component: UserCenter,
    children: [
      {
        path: 'index',
        name: '我的中心',
        component: () => import('../views/coaches/UserIndex.vue'),
        meta: {
          title: '我的中心'
        }
      },
      {
        path: 'info',
        name: '个人信息',
        component: () => import('../views/coaches/CoachInfo.vue'),
        meta: {
          title: '个人信息'
        }
      }
    ]
  }
]
