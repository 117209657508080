// 异步挂载的路由
// 动态需要根据权限加载的路由表
import LayoutIndex from '@/layout/Index.vue';
import UserCenter from '@/layout/UserCenter.vue';
import PersonalTailor from '@/layout/PersonalTailor.vue'

export const asyncUserRouterMap = [
  {
    path: '/',
    redirect: '/home',
    component: LayoutIndex,
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: {
          title: '首页'
        }
      }
    ]
  },
  {
    path: '/shop',
    component: LayoutIndex,
    children: [
      {
        path: 'index',
        name: 'ShopIndex',
        component: () => import('../views/shop/Index.vue'),
        meta: {
          title: '场馆介绍'
        }
      }
    ]
  },
  {
    path: '/course',
    name: '课程',
    component: LayoutIndex,
    children: [
      {
        path: 'index',
        name: '课程中心',
        component: () => import('../views/courses/Index.vue'),
        meta: {
          title: '课程中心'
        }
      },
      {
        path: 'pt',
        name: '私教中心',
        component: () => import('../views/courses/pt/Index.vue'),
        meta: {
          title: '私教中心'
        }
      },
      {
        path: 'pt-info',
        name: '私教课程详情',
        component: () => import('../views/courses/pt/PtInfo.vue'),
        meta: {
          title: '私教课程详情'
        }
      },
      {
        path: 'coach-info',
        name: 'coachinfo',
        component: () => import('../views/courses/pt/CoachInfo.vue'),
        meta: {
          title: '私教详情'
        }
      }
    ]
  },
  {
    path: '/course-info',
    name: '课程详情',
    component: () => import('../views/courses/CourseInfo.vue'),
    meta: {
      title: '课程详情'
    }
  },
  {
    path: '/course-buy',
    name: '购买课程',
    component: () => import('../views/courses/CourseBuy.vue'),
    meta: {
      title: '购买课程'
    }
  },
  {
    path: '/pt-buy',
    name: 'ptBuy',
    component: () => import('../views/courses/pt/PtBuy.vue'),
    meta: {
      title: '私教课程详情-购买'
    }
  },
  {
    path: '/payment',
    name: '支付结果',
    component: () => import('../views/webChat/payment.vue'),
    meta: {
      title: '支付结果'
    }
  },
  {
    path: '/user',
    name: '我的',
    component: UserCenter,
    children: [
      {
        path: 'index',
        name: '个人中心',
        component: () => import('../views/users/Index.vue'),
        meta: {
          title: '个人中心'
        }
      },
      {
        path: 'card',
        name: '我的会员卡',
        component: () => import('../views/users/Card.vue'),
        meta: {
          title: '我的会员卡'
        }
      },
      {
        path: 'buy-card',
        name: '购买会员卡',
        component: () => import('../views/users/BuyCard.vue'),
        meta: {
          title: '购买会员卡'
        }
      },
      {
        path: 'buy-card-order',
        name: '购卡下单',
        component: () => import('../views/users/BuyCardOrder.vue'),
        meta: {
          title: '购卡下单'
        }
      },
      {
        path: 'info',
        name: '个人信息',
        component: () => import('../views/users/UserInfo.vue'),
        meta: {
          title: '个人信息'
        }
      },
      {
        path: 'portrait',
        name: '人像采集',
        component: () => import('../views/users/Portrait.vue'),
        meta: {
          title: '人像采集'
        }
      },
      {
        path: 'course-order',
        name: '课程订单',
        component: () => import('../views/courses/CourseBuyOrder.vue'),
        meta: {
          title: '课程订单'
        }
      },
      {
        path: 'pt-order',
        name: '私教订单',
        component: () => import('../views/courses/pt/PtBuyOrder.vue'),
        meta: {
          title: '私教订单'
        }
      },
      {
        path: 'courses',
        name: '已购课程',
        component: () => import('../views/users/UserBuyCourse.vue'),
        meta: {
          title: '已购课程'
        }
      },
      {
        path: 'course-pt',
        name: '已购私教',
        component: () => import('../views/users/UserBuyPt.vue'),
        meta: {
          title: '已购私教'
        }
      }
    ]
  },
  {
    path: '/personal-tailor',
    name: 'PersonalTailor',
    component: PersonalTailor,
    children: [
      {
        path: 'chu-fang',
        name: '运动处方',
        component: () => import('../views/users/chufang/ChuFang.vue'),
        meta: {
          title: '运动处方'
        }
      },
      {
        path: 'basic-info/:aid',
        name: '基础信息',
        component: () => import('../views/users/chufang/BasicInformation.vue'),
        meta: {
          title: '运动处方-基础信息'
        }
      },
      {
        path: 'apparatus-measure/:aid',
        name: '仪器测量',
        component: () => import('../views/users/chufang/ApparatusMeasure.vue'),
        meta: {
          title: '运动处方-仪器测量'
        }
      },
      {
        path: 'body-analysis',
        name: '身体分析',
        component: () => import('../views/users/chufang/BodyAnalysis.vue'),
        meta: {
          title: '运动处方-身体分析'
        }
      },
      {
        path: 'fitness-plan',
        name: '健身计划',
        component: () => import('../views/users/chufang/FitnessPlan.vue'),
        meta: {
          title: '运动处方-健身计划'
        }
      },
      {
        path: 'fitness-plan-info',
        name: '健身计划详情',
        component: () => import('../views/users/chufang/FitnessPlan-Info.vue'),
        meta: {
          title: '运动处方-健身计划详情'
        }
      }
    ]
  },
  {
    path: '/user',
    name: '教练入驻',
    component: LayoutIndex,
    children: [
      {
        path: 'coachin',
        name: '教练入驻',
        component: () => import('../views/users/Coachin.vue'),
        meta: {
          title: '教练入驻'
        }
      }
    ]
  }
];
