// @ts-nocheck

import router from '../../router';
import {Module} from 'vuex';
import {PermissionState, RootState} from '../types'
import {asyncUserRouterMap} from '@/router/user-index'
import {asyncCoachRouterMap} from '@/router/coach-index'

const permission: Module<PermissionState, RootState> = {
  namespaced: true,
  state: {
    addRoutes: []
  },
  mutations: {
    SET_ROUTES(state, routers) {
      state.addRoutes = routers
    }
  },
  actions: {
    GenerateRoutes({commit}) {
      return new Promise((resolve) => {
        const roles = localStorage.getItem('Keep_ShipType')
        if (roles) {
          let accessedRouters = asyncUserRouterMap
          if (roles === 'coach') {
            accessedRouters = asyncCoachRouterMap
          }
          // 没有添加过才添加
          if (router.options.routes.length <= 2) {
            router.options.routes = router.options.routes.concat(accessedRouters);
            router.addRoutes(router.options.routes);
          }
          commit('SET_ROUTES', accessedRouters);
          resolve(accessedRouters);
        }
      });
    }
  }
};

export default permission;
