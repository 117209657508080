









import {Component, Vue} from 'vue-property-decorator';

@Component({})
export default class Tabbar extends Vue {
  private active = 'home';
};
