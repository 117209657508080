







import {Component, Vue} from 'vue-property-decorator';
import Tabbar from './components/Tabbar.vue';
import AppMain from './components/AppMain.vue';

@Component({
  components: {
    Tabbar,
    AppMain
  }
})
export default class LayoutIndex extends Vue {
};
