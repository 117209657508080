import request from '@/utils/request'

const url = '/webchat/account'

export function userLogin(code: string | Array<string | null>, state: string | Array<string | null>): any {
  return new Promise((resolve, reject) => {
    request({
      url: `${url}/user-login`,
      method: 'get',
      params: {
        code,
        state
      }
    }).then((res) => resolve(res.data)).catch((error) => reject(error))
  })
}

export function getAccessToken(openid: string): any {
  return new Promise((resolve, reject) => {
    request({
      url: `${url}/user-access-token`,
      method: 'get',
      params: {
        openid
      }
    }).then((res) => resolve(res.data)).catch((error) => reject(error))
  })
}
