







import {Component, Vue} from 'vue-property-decorator';
import CoachTabbar from './components/CoachTabbar.vue';
import AppMain from './components/AppMain.vue';

@Component({
  components: {
    CoachTabbar,
    AppMain
  }
})
export default class LayoutCoachIndex extends Vue {
}
