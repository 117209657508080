import axios from 'axios';
import {Toast} from 'vant';
import {AxiosRequestConfig} from 'axios';

import Vue from 'vue'

const service = axios.create({
  // baseURL: process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_BASE_API,
  timeout: 120000
});

// 添加 request 拦截器
service.interceptors.request.use((config: AxiosRequestConfig) => {
  // JWT
  if (config.headers) {
    config.headers['X-Requested-With'] = 'XMLHttpRequest';

    const token = Vue.prototype.$Cookies.get('Keep_Token')
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }

  }

  return config;

}, (error) => {
  return Promise.reject(error);
});

// 添加 response 拦截器
service.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response) {
    const response = error.response;
    const data = response.data;
    let message;
    if (data.error) {
      const resError = data.error;
      if (resError.message) {
        message = resError.message;
      } else if (resError.details) {
        message = resError.details;
      }
    }

    if (!message) {
      switch (error.response.status) {
        case 400:
          message = '发出的请求有错误，服务器没有进行新建或修改数据的操作。';
          break;
        case 401:
          message = '用户没有权限（令牌、用户名、密码错误）。';
          break;
        case 403:
          message = `${error.config.url} 访问被禁止。`;
          break;
        case 404:
          message = '发出的请求针对的是不存在的记录，服务器没有进行操作。';
          break;
        case 500:
          message = '服务器发生错误，请检查服务器。';
          break;
        case 502:
          message = '网关错误。';
          break;
        case 503:
          message = '服务不可用，服务器暂时过载或维护。';
          break;
        case 504:
          message = '网关超时。';
          break;
        default:
          message = '未知错误';
          break;
      }
    }

    Toast.fail({
      message,
      duration: 5 * 1000
    });

  } else if (error.message) {
    Toast.fail({
      message: error.message,
      duration: 5 * 1000
    });
  }
  return Promise.reject(error);
});


export default service;
