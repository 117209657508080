








import {Component, Vue} from 'vue-property-decorator';
import Tabbar from './components/Tabbar.vue';
import AppMain from './components/AppMain.vue';
import UserCenterHeader from './components/UserCenterHeader.vue';

@Component({
  components: {
    Tabbar,
    AppMain,
    UserCenterHeader
  }
})

export default class PersonalTailor extends Vue {
};
