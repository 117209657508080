import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/vant';

import './permission';

import Cookies from 'js-cookie'

Vue.prototype.$Cookies = Cookies

Vue.config.productionTip = false;

import axios from 'axios';
import request from './utils/request'

async function mountVue() {
  const settingUrl = `/appsettings.json?t=${new Date().getTime()}`
  const res = await axios.get(settingUrl);
  Object.defineProperty(Vue.prototype, '$appsettings', {
    get() {
      return res.data
    }
  });
  request.defaults.baseURL = res.data.ServerUrl + res.data.BaseApi

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
}

mountVue()
