









import {Component, Vue} from 'vue-property-decorator';
import Tabbar from './components/Tabbar.vue';
import AppMain from './components/AppMain.vue';
import UserCenterHeader from './components/UserCenterHeader.vue';
import CoachTabbar from '@/layout/components/CoachTabbar.vue';

@Component({
  components: {
    CoachTabbar,
    Tabbar,
    AppMain,
    UserCenterHeader
  }
})

export default class UserCenter extends Vue {
  get isCoach() {
    const shipType = localStorage.getItem('Keep_ShipType')
    return shipType === 'coach'
  }
};
