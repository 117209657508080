import {Module} from 'vuex';
import {AccountState, RootState} from '../types'
import {getAccessToken} from '@/api/webchatAuth'
import Vue from 'vue'

const account: Module<AccountState, RootState> = {
  namespaced: true,
  state: {
    token: ''
  },
  mutations: {
    SET_TOKEN(state: any, token: string) {
      state.token = token;
    }
  },
  actions: {
    async setToken({commit}, openid) {
      const result = await getAccessToken(openid)
      if (result.succeeded) {
        // 设置Cookie过期时间为23小时
        const date = new Date();
        const dateDate = date.setHours(date.getHours() + 23);
        const newDate = new Date(dateDate);
        Vue.prototype.$Cookies.set('Keep_Token', result.access_Token, {expires: newDate, path: '/'})
        localStorage.setItem('Keep_NickName', result.nickName)
        localStorage.setItem('Keep_FaceUrl', result.faceUrl)
        localStorage.setItem('Keep_ShipType', result.memberShipType)
        localStorage.setItem('Keep_RealName', result.realName)
        commit('SET_TOKEN', result.access_Token)
      }
    }
  }
}

export default account;
