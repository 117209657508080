import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// 所有权限通用路由表
// 如首页和登录页和一些不用权限的公用页面
export const constantRouterMap = [
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/WebChatAuth.vue'),
    meta: {
      title: '微信授权'
    }
  },
  {
    path: '/open',
    name: '开门码',
    component: () => import('../views/OpenCode.vue'),
    meta: {
      title: '开门码'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes: constantRouterMap,
});

export default router;
