// @ts-nocheck

import router from './router';
import store from './store'

import Vue from 'vue'
import permission from '@/store/modules/permission';
import versionTood from '@/utils/versionUpdate'


router.beforeEach(async (to, from, next) => {
  versionTood.isNewVersion()

  if (to && to.meta.title) {
    document.title = to.meta.title;
  }

  if (to.name === 'Auth') {
    next()
    return
  }

  let openid = localStorage.getItem('Keep_OpenId');
  if (process.env.NODE_ENV === 'development') {
    openid = Vue.prototype.$appsettings.OpenId
    localStorage.setItem('Keep_OpenId', openid);
  }
  if (!openid) {
    const redirectUrl = encodeURIComponent('http://h5.njsjjs.com/auth')
    const appid = Vue.prototype.$appsettings.AppId
    const state = Vue.prototype.$appsettings.State
    if (appid) {
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`
    }
  } else {
    const token = Vue.prototype.$Cookies.get('Keep_Token');
    if (!token) {
      await store.dispatch('account/setToken', openid);
      next({path: to.path})
    } else {
      if (store.state.permission.addRoutes.length <= 0) {
        await store.dispatch('permission/GenerateRoutes');
        next({path: to.path})
      } else {
        next();
      }
    }
  }
});

