import Vue from 'vue';
import Vuex, {StoreOptions} from 'vuex';

import {RootState} from './types';
import account from './modules/account';
import getters from './getters';
import permission from './modules/permission'

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    account,
    permission
  },
  getters
};

export default new Vuex.Store<RootState>(store);
